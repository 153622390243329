<template>
  <div class="space-y-6">
    <!-- Everyone Access Toggle -->
    <div
      class="flex flex-col space-y-2 rounded-lg bg-gray-50 p-4 sm:flex-row sm:items-center sm:justify-between sm:space-y-0"
    >
      <label class="text-md font-medium text-gray-700">{{ $t('Access') }}</label>
      <div class="sm:ml-4 sm:w-1/2">
        <front-checkbox-input
          :label="$t('Allow everyone to access')"
          :model-value="modelValue.everyone"
          @update:model-value="handleEveryoneAccess"
        />
      </div>
    </div>

    <!-- Granular Access Controls -->
    <template v-if="!modelValue.everyone">
      <div class="space-y-4">
        <!-- Users -->
        <div class="flex items-center justify-between gap-4">
          <label class="text-md font-medium text-gray-700" :name="$t('User(s)')">{{ $t('User(s)') }}</label>
          <MultiSelectInput
            class-names="w-3/4"
            :error="null"
            label="name"
            :model-value="modelValue.users ?? []"
            :name="$t('User(s)')"
            :placeholder="$t('Search for users...')"
            :url="route('front.admin.manuals.search.users')"
            @update:model-value="handleUpdate('users', $event)"
          />
        </div>

        <!-- User Roles -->
        <div class="flex items-center justify-between gap-4">
          <label class="text-md font-medium text-gray-700" :name="$t('User(s)')">{{ $t('User Role(s)') }}</label>
          <MultiSelectInput
            class-names="w-3/4"
            :error="null"
            label="name"
            :model-value="modelValue.user_roles ?? []"
            :name="$t('User Role(s)')"
            :placeholder="$t('Select User Role(s)')"
            :url="route('front.admin.manuals.search.roles')"
            @update:model-value="handleUpdate('user_roles', $event)"
          />
        </div>

        <!-- User Roles -->
        <div class="flex items-center justify-between gap-4">
          <label class="text-md font-medium text-gray-700" :name="$t('User(s)')">{{ $t('Job Profile(s)') }}</label>
          <MultiSelectInput
            class-names="w-3/4"
            :error="null"
            label="name"
            :model-value="modelValue.job_profiles ?? []"
            :name="$t('User Job Profile(s)')"
            :placeholder="$t('Select Job Profile(s)')"
            :url="route('front.admin.manuals.search.job-profiles')"
            @update:model-value="handleUpdate('job_profiles', $event)"
          />
        </div>

        <!-- Locations -->
        <div class="flex items-center justify-between gap-4">
          <label class="text-md font-medium text-gray-700" :name="$t('User(s)')">{{ $t('Location(s)') }}</label>
          <MultiSelectInput
            class-names="w-3/4"
            :error="null"
            label="name"
            :model-value="modelValue.locations ?? []"
            :name="$t('Location(s)')"
            :placeholder="$t('Search for locations...')"
            :url="route('front.admin.manuals.search.locations')"
            @update:model-value="handleUpdate('locations', $event)"
          />
        </div>

        <!-- Groups -->
        <div class="flex items-center justify-between gap-4">
          <label class="text-md font-medium text-gray-700" :name="$t('User(s)')">{{ $t('Group(s)') }}</label>
          <MultiSelectInput
            v-model="modelValue.groups"
            class-names="w-3/4"
            :error="null"
            label="name"
            :name="$t('Group(s)')"
            :placeholder="$t('Search for groups...')"
            :url="route('front.admin.manuals.search.groups')"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import MultiSelectInput from '@/Shared/Forms/MultiSelectInput.vue'
import FrontCheckboxInput from '@/Shared/FrontCheckboxInput.vue'

export default {
  components: {
    MultiSelectInput,
    FrontCheckboxInput,
  },
  props: {
    modelValue: {
      type: Object,
      required: true,
      default: () => defaultValues(),
    },
    organization: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  methods: {
    handleEveryoneAccess(value) {
      const updatedPermissions = { everyone: value }
      if (value) {
        Object.keys(this.modelValue).forEach((key) => {
          if (key !== 'everyone') {
            updatedPermissions[key] = []
          }
        })
      }
      this.$emit('update:modelValue', updatedPermissions)
    },
    handleUpdate(key, value) {
      const updatedPermissions = { ...this.modelValue, [key]: value }
      this.$emit('update:modelValue', updatedPermissions)
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style>
.qualify-red-theme.multiselect .multiselect__tags {
  border-radius: 0.25rem;
  border-color: #d1d5db;
}

.qualify-red-theme.multiselect .multiselect__tag {
  background-color: #ef4444;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__tag-icon:after {
  color: white;
}

.qualify-red-theme.multiselect .multiselect__tag-icon:hover {
  background-color: #dc2626;
}

.qualify-red-theme.multiselect .multiselect__option--highlight {
  background-color: #ef4444;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__option--highlight:after {
  background-color: #ef4444;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__option--selected.multiselect__option--highlight {
  background-color: #dc2626;
  color: white;
}

.qualify-red-theme.multiselect .multiselect__option--selected.multiselect__option--highlight:after {
  background-color: #dc2626;
  color: white;
}
</style>
