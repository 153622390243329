<template>
  <div :class="classNames">
    <Multiselect
      deselect-label=""
      :hide-label="true"
      :hide-selected="true"
      :label="label"
      :loading="isSearching"
      :model-value="modelValue"
      :multiple="true"
      :name="name"
      :options="optionsList"
      :placeholder="placeholder"
      v-bind="$attrs"
      select-label=""
      track-by="id"
      @search-change="search"
      @update:model-value="(value) => $emit('update:modelValue', value)"
    >
      <template #noResult>
        <slot name="noResult">{{ $t('No record(s) found.') }}</slot>
      </template>
      <template #noOptions>
        <slot name="noOptions">{{ $t('No record(s) found.') }}</slot>
      </template>
      <slot />
    </Multiselect>
    <div v-if="error" class="form-error">{{ error }}</div>
  </div>
</template>

<script>
import Http from '@/Utils/Http'
import _debounce from 'lodash/debounce'
import Multiselect from 'vue-multiselect'

export default {
  components: { Multiselect },

  inheritAttrs: false,
  props: {
    modelValue: Object,
    url: String,
    trackBy: String,
    searchBy: [String, Array],
    options: [Array],
    name: String,
    label: String,
    placeholder: String,
    error: String,
    classNames: String,
  },
  emits: ['update:modelValue'],
  data() {
    return {
      optionsList: this.options || [],
      isSearching: false,
    }
  },
  watch: {
    url: _debounce(function () {
      this.search()
    }, 300),
  },
  mounted() {
    this.search()
  },
  methods: {
    search(query) {
      this.isSearching = true
      Http.get(this.url, { params: { search: query } })
        .then((response) => {
          if (Array.isArray(response.data)) {
            this.optionsList = response.data
            return
          } else if (Array.isArray(response.data?.data)) {
            this.optionsList = response.data.data
            return
          }
          this.optionsList = response.data.data
        })
        .finally(() => {
          this.isSearching = false
        })
    },
  },
}
</script>
