<template>
  <front-layout :title="$t('Edit Manual')">
    <div class="item-center mb-8 flex max-w-2xl justify-between">
      <breadcrumb
        class="font-heading text-2xl font-semibold"
        :name="form.name ?? $t('Edit')"
        :previous-name="$t('Manuals')"
        :previous-url="route('front.admin.manuals')"
      />
      <FrontLoadingButton :loading="form.processing" @click="submit">
        {{ $t('Save') }}
      </FrontLoadingButton>
    </div>
    <form @submit.prevent="submit">
      <div class="max-w-2xl rounded bg-white shadow">
        <div class="flex flex-col p-8">
          <div class="w-full pb-8">
            <FrontTextInput v-model="form.name" :error="form.errors.name" :label="$t('Manual Name')" />
          </div>
          <FrontDynamicAccessControl v-model="form.permissions" :organization="organization" />
        </div>
      </div>
      <!-- Sections -->
      <div class="pb-6 pt-8 font-heading text-xl">Sections</div>
      <Draggable v-model="form.sections">
        <div
          v-for="(section, index) in form.sections"
          :key="index"
          class="flex max-w-2xl flex-col overflow-hidden bg-white p-6 shadow"
        >
          <div class="flex items-center">
            <div class="">
              <icon class="h-8 w-8 text-gray-400" name="menu" />
            </div>
            <div class="w-full pl-4 text-lg">
              {{ section.name }}
            </div>
            <div v-if="section.id" class="flex items-center justify-end">
              <front-outline-button-link
                class="mr-6 flex"
                :href="route('front.admin.manual-sections.edit', { manual: manual, manualSection: section })"
              >
                Edit
              </front-outline-button-link>
              <button class="flex justify-end text-gray-500 hover:text-gray-600" @click="removeSection(index)">
                Remove
              </button>
            </div>
          </div>
        </div>
      </Draggable>
    </form>
    <div class="mt-8 flex max-w-2xl justify-end">
      <front-outline-button class="flex" @click="addSection">
        <icon class="fill-qualify-red mr-2 h-4 w-4 flex-shrink-0" name="plus" />
        Add Section
      </front-outline-button>
    </div>
  </front-layout>
</template>

<script>
import Breadcrumb from '@/Shared/Breadcrumb.vue'
import FrontDynamicAccessControl from '@/Shared/FrontDynamicAccessControl.vue'
import FrontLayout from '@/Shared/FrontLayout.vue'
import FrontLoadingButton from '@/Shared/FrontLoadingButton.vue'
import FrontOutlineButton from '@/Shared/FrontOutlineButton.vue'
import FrontOutlineButtonLink from '@/Shared/FrontOutlineButtonLink.vue'
import FrontTextInput from '@/Shared/FrontTextInput.vue'
import Icon from '@/Shared/Icon.vue'
import { useForm } from '@inertiajs/vue3'
import { VueDraggableNext as Draggable } from 'vue-draggable-next'

export default {
  components: {
    Draggable,
    Breadcrumb,
    FrontDynamicAccessControl,
    FrontLayout,
    FrontLoadingButton,
    FrontOutlineButton,
    FrontOutlineButtonLink,
    FrontTextInput,
    Icon,
  },
  props: {
    manual: Object,
    manual_sections: Array,
    organization: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        name: this.manual.name,
        sections: this.manual_sections,
        permissions: this.manual.permissions ?? {
          users: [],
          user_roles: [],
          job_profiles: [],
          locations: [],
          groups: [],
          everyone: false,
        },
      }),
    }
  },
  methods: {
    addSection() {
      this.$inertia.post(
        this.route('front.admin.manual-sections.create', this.manual),
        {
          name: this.$t('New Section :index', { index: this.form.sections.length + 1 }),
        },
        {
          preserveState: false,
        }
      )
    },
    removeSection(index) {
      this.form.sections.splice(index, 1)
    },
    submit() {
      this.form
        .transform((data) => {
          // Ensure permissions are properly formatted before submission
          data.permissions = Object.keys(data.permissions).reduce(
            (acc, key) => {
              if (key !== 'everyone') {
                acc[key] = Array.isArray(this.form.permissions[key])
                  ? this.form.permissions[key].map((item) => (typeof item === 'object' ? item.id : item))
                  : []
              } else {
                acc[key] = this.form.permissions[key] === true
              }
              return acc
            },
            { everyone: data.permissions.everyone }
          )

          return data
        })
        .put(this.route('front.admin.manuals.update', this.manual), {
          onSuccess: () => {
            console.log('Form submitted successfully')
          },
          onError: (errors) => {
            console.error('Form submission errors:', errors)
          },
          preserveScroll: true,
        })
    },
  },
}
</script>
